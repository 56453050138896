import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (args, thunkApi) => {
    const {
      page,
      size = 10,
      query = "",
      queryPhone = "",
      filter = "{}",
      controller = new AbortController(),
    } = args;
    try {
      const { data } = await axios.get(
        `/api/get_orders?page=${page}&size=${size}&query=${query}&queryPhone=${queryPhone}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
          signal: controller?.signal, // Attach abort signal
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      // Check if the error is due to request cancellation
      if (axios.isCancel(errors)) {
        console.log("Request canceled", errors.message);
        return thunkApi.rejectWithValue({ canceled: true });
      }

      // Handle other errors (non-400 or 400-specific)
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_order", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const createOrders = createAsyncThunk(
  "orders/createOrders",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/create_orders", args, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async (args, thunkApi) => {
    const abortController = new AbortController();
    const timeoutId = setTimeout(() => abortController.abort(), 3000);

    try {
      const { data } = await axios.put(
        `/api/update_order/${args._id}`,
        args.values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
          // signal: abortController.signal,
        }
      );

      clearTimeout(timeoutId);
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      clearTimeout(timeoutId);

      if (errors.name === "AbortError") {
        return thunkApi.rejectWithValue({
          errors: [{ msg: "Request aborted: Timeout exceeded" }],
        });
      }

      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateOrderCallAction = createAsyncThunk(
  "orders/updateOrderCallAction",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_order_call_action/${args._id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateOrdersStatus = createAsyncThunk(
  "orders/updateOrdersStatus",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put("/api/update_orders_status", args, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateCancelStatus = createAsyncThunk(
  "orders/updateCancelStatus",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put("/api/cancel_orders", args, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const updateOrderShippingCompany = createAsyncThunk(
  "orders/updateOrderShippingCompany",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.put(
        `/api/update_order_shipping_company`,
        args,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

export const createNote = createAsyncThunk(
  "orders/createNote",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post(
        `/api/create_order_note/${args.id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (errors) {
      return thunkApi.rejectWithValue(
        errors.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : errors.response.data
      );
    }
  }
);

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get orders
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        console.log(action.payload, "this is testign ");
        if (action.payload?.canceled) {
          state.errors = [];
        } else {
          state.errors = action.payload.errors;
        }
      })

      // Create order
      .addCase(createOrder.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Create multiple orders
      .addCase(createOrders.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(createOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Update order
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        const indexAt = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (indexAt !== -1) {
          state.data.data[indexAt] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Update orders status
      .addCase(updateOrdersStatus.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateOrdersStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateOrdersStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Update cancel status
      .addCase(updateCancelStatus.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateCancelStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateCancelStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Update order call action
      .addCase(updateOrderCallAction.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateOrderCallAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateOrderCallAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Update order shipping company
      .addCase(updateOrderShippingCompany.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateOrderShippingCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.errors = [];
      })
      .addCase(updateOrderShippingCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Create note
      .addCase(createNote.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createNote.fulfilled, (state, action) => {
        state.isLoading = false;
        const indexAt = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (indexAt !== -1) {
          state.data.data[indexAt] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(createNote.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default ordersSlice.reducer;
